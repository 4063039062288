import styled from 'styled-components';

export const ContainerInputs = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 1.25rem;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  .inputs-date,
  .inputs-seletor {
    display: flex;
    flex-direction: row;
    gap: 0.9375rem;
  }

  .inputs-seletor {
    width: 100%;

    input {
      width: 17.8438rem;

      @media screen and (max-width: 1399px) {
        width: 14.0625rem;
      }

      @media screen and (max-width: 1199px) {
        width: 10.3125rem;
      }

      @media screen and (max-width: 991px) {
        width: 11.875rem;
      }
    }
  }
`;
