import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React from 'react';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';
import { Box } from '@material-ui/core';
import { formataPDFValue } from '../../utils/formataPDFValue';

export const CustomDataGrid: React.FC = () => {
  const { itemsGrid, loading } = useApuracaoDePisCofins();

  const tabColumns: GridColumns = [
    { field: 'id', hide: true },
    {
      field: 'cod_loja',
      headerName: 'Loja',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'cfop',
      headerName: 'CFOP',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'cst_pis',
      headerName: 'CST PIS',
      align: 'left',
      headerAlign: 'left',
      width: 110,
    },
    {
      field: 'cst_cofins',
      headerName: '	CST COFINS',
      align: 'left',
      headerAlign: 'left',
      width: 140,
    },
    {
      field: 'val_total',
      headerName: 'Valor Contábil',
      align: 'right',
      headerAlign: 'right',
      width: 150,
    },
    {
      field: 'val_icms_st',
      headerName: 'ICMS ST',
      align: 'right',
      headerAlign: 'right',
      width: 120,
    },
    {
      field: 'val_base_pis',
      headerName: 'Base PIS',
      align: 'right',
      headerAlign: 'right',
      width: 120,
    },
    {
      field: 'per_pis',
      headerName: '% PIS',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'val_pis',
      headerName: 'Valor PIS',
      align: 'right',
      headerAlign: 'right',
      width: 120,
      cellClassName: 'cell-apuracao-val-pis',
    },
    {
      field: 'val_base_cofins',
      headerName: 'Base COFINS',
      align: 'right',
      headerAlign: 'right',
      width: 150,
    },
    {
      field: 'per_cofins',
      headerName: '% COFINS',
      align: 'right',
      headerAlign: 'right',
      width: 120,
    },
    {
      field: 'val_cofins',
      headerName: 'Valor COFINS',
      align: 'right',
      headerAlign: 'right',
      width: 150,
      cellClassName: 'cell-apuracao-val-cofins',
    },
    {
      field: 'val_aliq_zero',
      headerName: 'Val. Aliq. Zero',
      align: 'right',
      headerAlign: 'right',
      width: 150,
    },
    {
      field: 'val_monofasico',
      headerName: 'Val. Monofásico',
      align: 'right',
      headerAlign: 'right',
      width: 170,
    },
    {
      field: 'val_st',
      headerName: 'Val. Subst.',
      align: 'right',
      headerAlign: 'right',
      width: 130,
    },
    {
      field: 'val_imune',
      headerName: 'Val. Imune',
      align: 'right',
      headerAlign: 'right',
      width: 130,
    },
    {
      field: 'val_suspensao',
      headerName: 'Val. Suspensão',
      align: 'right',
      headerAlign: 'right',
      width: 160,
    },
    {
      field: 'val_isento_pis_cof',
      headerName: 'Val. Isento',
      align: 'right',
      headerAlign: 'right',
      width: 130,
    },
    {
      field: 'val_nao_tribut_pis_cof',
      headerName: 'Val. Não Tributado',
      align: 'right',
      headerAlign: 'right',
      width: 180,
    },
    {
      field: 'val_ipi',
      headerName: 'Val. IPI',
      align: 'right',
      headerAlign: 'right',
      width: 110,
    },
    {
      field: 'val_desconto',
      headerName: 'Val. Desconto',
      align: 'right',
      headerAlign: 'right',
      width: 150,
    },
  ];

  return (
    <Box sx={{ height: 350, width: 'auto' }}>
      <DataGrid
        rows={formataPDFValue(itemsGrid)}
        columns={tabColumns}
        loading={loading}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        hideFooter
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar colunas',
        }}
      />
    </Box>
  );
};
