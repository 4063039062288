import { ApuracaoPisCofinsProps } from '../types';

export const somaCampo = (
  arr: ApuracaoPisCofinsProps[],
  field: keyof ApuracaoPisCofinsProps,
  toFixed?: boolean,
): number | string => {
  const soma = arr.reduce((acc, item) => {
    const value = item[field];
    if (typeof value === 'number') return acc + value;
    return acc;
  }, 0);

  if (!toFixed) return soma;
  return soma.toFixed(2);
};
