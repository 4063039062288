export const totalizadoresArray = [
  { label: 'Créd. PIS', key: 'cred_pis' },
  { label: 'Déb. PIS', key: 'deb_pis' },
  { label: 'Imp. PIS', key: 'imp_pis' },
  { label: 'Créd. COFINS', key: 'cred_cofins' },
  { label: 'Déb. COFINS', key: 'deb_cofins' },
  { label: 'Imp. COFINS', key: 'imp_cofins' },
  { label: 'Créd. PIS/COFINS', key: 'cred_pis_cofins' },
  { label: 'Déb. PIS/COFINS', key: 'deb_pis_cofins' },
  { label: 'Imp. PIS/COFINS', key: 'imp_pis_cofins' },
  { label: 'Tot Incid Cpra', key: 'tot_incid_cpra' },
  { label: 'Tot Ñ Incid Cpra', key: 'tot_n_incid_cpra' },
  { label: 'Tot Incid. Vda', key: 'tot_incid_vda' },
  { label: 'Tot Ñ Incid Vda', key: 'tot_n_incid_vda' },
];
