import React from 'react';
import { FaEraser } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { ContainerButtons } from './styles';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';

export const ScreenButtons: React.FC = () => {
  const { onSearch, handleCancel, disableButtons, handleClear, loading } =
    useApuracaoDePisCofins();

  return (
    <ContainerButtons>
      <CustomButtonNovo
        disabled={disableButtons.search || loading}
        onClick={async () => {
          await onSearch();
        }}
        label="Pesquisar"
        icon={IoIosSearch}
      />
      <CustomButtonNovo
        variant="cancel"
        disabled={disableButtons.cancel}
        onClick={handleCancel}
        label="Cancelar"
        icon={MdOutlineCancel}
      />
      <CustomButtonNovo
        variant="clear"
        disabled={disableButtons.clear || loading}
        onClick={handleClear}
        label="Limpar"
        icon={FaEraser}
      />
    </ContainerButtons>
  );
};
