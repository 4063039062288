import React, { useCallback, useContext } from 'react';
import useAuth from '~/hooks/useAuth';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import { toast } from 'react-toastify';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { BsFillLightningFill } from 'react-icons/bs';
import api from '~/services/api';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

export const ButtonAcessaReprocessa: React.FC = () => {
  const { user } = useAuth();
  const { loading, searchData, itemsGrid } = useApuracaoDePisCofins();
  const history = useHistory();
  const { addScreenInfo } = useContext(InformacaoTelasAcessadasContext);
  const queryClient = useQueryClient();

  const handleClick = useCallback(async () => {
    if (!user)
      return toast.warning(
        'Não foi possível encontrar usuário, tente novamente ou entre em contato com o suporte',
      );

    const hasReprocessamentoPermission = user.controleAcessoTela.some(
      (controle) => controle.cod_controle === -2 && controle.cod_tela === 293,
    );

    if (!hasReprocessamentoPermission)
      return toast.warning(
        'Usuário sem permissão à tela de Reprocessamento de PIS/COFINS, contate seu administrador do sistema',
      );

    if (!user.cod_controle)
      return toast.warning('Usuário sem código de controle');

    const { data } = await api.get(`/telasModulo/${user.cod_controle}`);

    if (!data.success) return;

    const modulos = data.data;
    const fiscalModule = modulos.find(
      (modulo: any) => modulo.cod_modulo === 23,
    );

    if (!fiscalModule) return;

    const reprocessamentoScreen = fiscalModule.telas.find(
      (tela: any) => tela.cod_tela === 293,
    );

    if (!reprocessamentoScreen) return;

    const telaAdd = {
      label: reprocessamentoScreen.label_menu,
      active: true,
      lib_icon: reprocessamentoScreen.lib_icon,
      des_icon: reprocessamentoScreen.des_icon,
      cod_tela: reprocessamentoScreen.cod_tela,
      url: 'reprocessa-pis-cofins',
      linkMaterial: reprocessamentoScreen.link_material,
      flg_abre_pesquisa: reprocessamentoScreen.flg_abre_pesquisa,
    };

    addScreenInfo(telaAdd);

    if (searchData && itemsGrid.length > 0)
      queryClient.setQueryData('cache_tela_295', searchData);

    history.push('reprocessa-pis-cofins');
  }, [addScreenInfo, history, itemsGrid.length, queryClient, searchData, user]);
  return (
    <CustomButtonNovo
      onClick={async () => {
        await handleClick();
      }}
      label="Reprocessar PIS/COFINS"
      width="auto"
      icon={BsFillLightningFill}
      style={{ backgroundColor: '#cccc18' }}
      disabled={loading}
    />
  );
};
