import React from 'react';
import Loja from '~/components/Loja';
import { ScreenInputs } from '../ScreenInputs';
import { ScreenButtons } from '../ScreenButtons';
import { ScreenFormContanier } from './styles';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';

export const ScreenForm: React.FC = () => {
  const { disableForm, onChangeCodLoja, loading } = useApuracaoDePisCofins();

  return (
    <ScreenFormContanier>
      <Loja
        disabled={disableForm || loading}
        isMulti
        resetLojas={false}
        onChange={(value) => {
          onChangeCodLoja(typeof value === 'number' ? [value] : value);
        }}
      />
      <ScreenInputs />
      <ScreenButtons />
    </ScreenFormContanier>
  );
};
