import React from 'react';
import { ApuracaoDePisCofinsContent } from './ApuracaoDePisCofinsContent';
import { ApuracaoDePisCofinsProvider } from './contexts/ApuracaoDePisCofinsContext';

export const ApuracaoDePisCofins: React.FC = () => {
  return (
    <ApuracaoDePisCofinsProvider>
      <ApuracaoDePisCofinsContent />
    </ApuracaoDePisCofinsProvider>
  );
};
