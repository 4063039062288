import api from '~/services/api';
import { PayloadIndexApuracaoProps, ResponseRelatorio } from '../types';

const indexApuracao = async ({
  params,
}: PayloadIndexApuracaoProps): Promise<ResponseRelatorio> => {
  const { data } = await api.get<ResponseRelatorio>(
    '/apuracao-pis-cofins/index',
    { params },
  );
  return data;
};

export const apuracaoServices = { indexApuracao };
