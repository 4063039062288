import React, { useCallback } from 'react';
import { FaPrint } from 'react-icons/fa';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';
import PDFGenerator from '../../classes/PDFGenerator';

export const PrintButton: React.FC = () => {
  const { itemsGrid, dates, loading, infoLoja } = useApuracaoDePisCofins();

  const handlePrint = useCallback(() => {
    if (itemsGrid.length > 0 && dates.dta_inicio && dates.dta_fim && infoLoja) {
      const pdfGenerator = new PDFGenerator(
        itemsGrid,
        {
          dta_inicio: dates.dta_inicio,
          dta_fim: dates.dta_fim,
        },
        infoLoja,
      );
      pdfGenerator.generatePDF();
    }
  }, [dates.dta_fim, dates.dta_inicio, infoLoja, itemsGrid]);

  return (
    <CustomButtonNovo
      onClick={handlePrint}
      label="IMPRIMIR"
      icon={FaPrint}
      style={{ backgroundColor: '#666666' }}
      disabled={itemsGrid.length <= 0 || loading}
    />
  );
};
