import styled from 'styled-components';

export const ContainerToalizadores = styled.section`
  display: grid;
  grid-template-rows: repeat(4, auto);
  gap: 0.625rem;

  .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.625rem;
  }

  .item {
    text-align: start;
  }

  .item.empty {
    visibility: hidden;
  }

  .item.last {
    justify-self: end;
  }
`;
