import React from 'react';
import { InputDate, InputSelect } from '~/components/NovosInputs';
import { ContainerInputs } from './styles';
import { operacao } from '../../utils/operacao';
import { pisCofins } from '../../utils/pis-cofins';
import { tipoNaoIncidencia } from '../../utils/tipo-nao-incidencia';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';

export const ScreenInputs: React.FC = () => {
  const {
    formTela,
    disableForm,
    disableTipoNaoIncidencia,
    onChangeDisableTipoNaoIncidencia,
    containerInputsRef,
    loading,
  } = useApuracaoDePisCofins();

  const {
    register,
    control,
    setValue,
    clearErrors,
    resetField,
    formState: { errors },
  } = formTela;

  return (
    <ContainerInputs ref={containerInputsRef}>
      <div className="inputs-date">
        <InputDate
          label="Início:"
          register={register}
          name="dta_inicio"
          disabled={disableForm || loading}
          isError={!!errors.dta_inicio}
          control={control}
          onInput={(ev: any) => {
            clearErrors('dta_inicio');
            setValue('dta_inicio', ev.target.value);
          }}
        />
        <InputDate
          label="Término:"
          register={register}
          name="dta_fim"
          disabled={disableForm || loading}
          isError={!!errors.dta_fim}
          control={control}
          onInput={(ev: any) => {
            clearErrors('dta_fim');
            setValue('dta_fim', ev.target.value);
          }}
        />
      </div>
      <div className="inputs-seletor">
        <InputSelect
          label="Operação:"
          placeholder="Todos"
          name="tipo_operacao"
          register={register}
          isError={!!errors.tipo_operacao}
          control={control}
          options={operacao}
          disabled={disableForm || loading}
          changeSelected={(selected) => {
            setValue('tipo_operacao', selected);
          }}
        />
        <InputSelect
          label="PIS/COFINS:"
          placeholder="Todos"
          name="flg_nao_pis_cofins"
          register={register}
          isError={!!errors.flg_nao_pis_cofins}
          control={control}
          options={pisCofins}
          disabled={disableForm || loading}
          changeSelected={(selected) => {
            setValue('flg_nao_pis_cofins', selected);
            onChangeDisableTipoNaoIncidencia(selected.value !== 1);

            if (selected.value !== 1) {
              resetField('tipo_nao_pis_cofins', {
                defaultValue: tipoNaoIncidencia[0],
              });
              setValue('tipo_nao_pis_cofins', tipoNaoIncidencia[0]);

              if (containerInputsRef.current) {
                const input: HTMLInputElement | null =
                  containerInputsRef.current.querySelector(
                    '[name="tipo_nao_pis_cofins"]',
                  );

                if (input) input.value = 'Todos';
              }
            }
          }}
        />
        <InputSelect
          label="Tipo Ñ Inc.:"
          placeholder="Todos"
          name="tipo_nao_pis_cofins"
          register={register}
          isError={!!errors.tipo_nao_pis_cofins}
          control={control}
          isDisabled={disableTipoNaoIncidencia || disableForm || loading}
          disabled={disableTipoNaoIncidencia || disableForm || loading}
          options={tipoNaoIncidencia}
          changeSelected={(selected) => {
            setValue('tipo_nao_pis_cofins', selected);
          }}
        />
      </div>
    </ContainerInputs>
  );
};
