import React from 'react';
import { Container } from './styles';
import { ScreenForm } from './components/ScreenForm';
import FooterDefault from '~/components/FooterDefault';
import { CustomDataGrid } from './components/CustomDataGrid';
import { Totalizadores } from './components/Totalizadores';
import { PrintButton } from './components/PrintButton';
import { ButtonAcessaReprocessa } from './components/ButtonAcessaReprocessa';

export const ApuracaoDePisCofinsContent: React.FC = () => {
  return (
    <Container>
      <h2>Apuração de Pis/Cofins</h2>
      <article className="main-content">
        <ScreenForm />
        <CustomDataGrid />
      </article>
      <article className="totalizadores-buttons">
        <Totalizadores />
        <div className="container-buttons">
          <ButtonAcessaReprocessa />
          <PrintButton />
        </div>
      </article>
      <FooterDefault codTela={295} />
    </Container>
  );
};
