/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApuracaoGridProps } from '../types/context';
import { moneyFormat } from '~/utils/functions';
import { formataPDFValue } from '../utils/formataPDFValue';
import { headerPDF } from '../utils/headerPDF';
import { HeaderPdfProps, InfoLojaProps } from '../types';
import { format, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PDFGenerator {
  private docDefinition: any;
  private totalEntrada: { [key: string]: number } = {};
  private totalSaida: { [key: string]: number } = {};
  private totalInUFEntrada: { [key: string]: number } = {};
  private totalOutUFEntrada: { [key: string]: number } = {};
  private totalInUFSaida: { [key: string]: number } = {};
  private totalOutUFSaida: { [key: string]: number } = {};
  private dummy: boolean;

  constructor(
    private items: ApuracaoGridProps[],
    private dates: { dta_inicio: Date; dta_fim: Date },
    private loja: InfoLojaProps,
  ) {
    this.docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: 7,
      content: [],
    };
    this.calculateTotals();
    this.calculateUFsEntrada();
    this.calculateUFsSaida();
    this.dummy = false;
  }

  private static getPageWidth() {
    const pageSize = { width: 690, height: 595 };
    return pageSize.width;
  }
  private static getVisibleHeaders(): HeaderPdfProps[] {
    return headerPDF.filter((header) => !header.hide);
  }
  private static getValColumns(headers: HeaderPdfProps[]): string[] {
    return headers
      .map((header) => header.key)
      .filter((field) => field.startsWith('val_'));
  }
  private static initializeTotals(columns: string[]): {
    [key: string]: number;
  } {
    const totals: { [key: string]: number } = {};
    columns.forEach((column) => {
      totals[column] = 0;
    });
    return totals;
  }

  private calculateTotals() {
    const visibleHeaders = PDFGenerator.getVisibleHeaders();
    const valColumns = PDFGenerator.getValColumns(visibleHeaders);

    this.totalEntrada = PDFGenerator.initializeTotals(valColumns);
    this.totalSaida = PDFGenerator.initializeTotals(valColumns);

    this.items.forEach((item: any) => {
      const isEntrada = item.tipo_operacao === 0;
      valColumns.forEach((column) => {
        const value = parseFloat(item[column] as string) || 0;
        if (isEntrada) {
          this.totalEntrada[column] += value;
        } else {
          this.totalSaida[column] += value;
        }
      });
    });
  }
  private calculateUFs(
    items: ApuracaoGridProps[],
    totalInUF: { [key: string]: number },
    totalOutUF: { [key: string]: number },
  ) {
    const visibleHeaders = PDFGenerator.getVisibleHeaders();
    const valColumns = PDFGenerator.getValColumns(visibleHeaders);

    this.dummy = true;

    items.forEach((item: any) => {
      const flag = item.flg_dentro_estado;
      valColumns.forEach((column) => {
        const value = parseFloat(item[column] as string) || 0;
        if (flag) {
          totalInUF[column] += value;
        } else {
          totalOutUF[column] += value;
        }
      });
    });

    this.dummy = false;
  }
  private calculateUFsEntrada() {
    this.totalInUFEntrada = PDFGenerator.initializeTotals(
      PDFGenerator.getValColumns(PDFGenerator.getVisibleHeaders()),
    );
    this.totalOutUFEntrada = PDFGenerator.initializeTotals(
      PDFGenerator.getValColumns(PDFGenerator.getVisibleHeaders()),
    );

    this.calculateUFs(
      this.items.filter((item) => item.tipo_operacao === 0),
      this.totalInUFEntrada,
      this.totalOutUFEntrada,
    );
  }
  private calculateUFsSaida() {
    this.totalInUFSaida = PDFGenerator.initializeTotals(
      PDFGenerator.getValColumns(PDFGenerator.getVisibleHeaders()),
    );
    this.totalOutUFSaida = PDFGenerator.initializeTotals(
      PDFGenerator.getValColumns(PDFGenerator.getVisibleHeaders()),
    );

    this.calculateUFs(
      this.items.filter((item) => item.tipo_operacao === 1),
      this.totalInUFSaida,
      this.totalOutUFSaida,
    );
  }

  private addInfoLoja() {
    const { loja } = this;

    const diasDaSemana = [
      'DOMINGO',
      'SEGUNDA-FEIRA',
      'TERÇA-FEIRA',
      'QUARTA-FEIRA',
      'QUINTA-FEIRA',
      'SEXTA-FEIRA',
      'SÁBADO',
    ];
    const dataAtual = new Date();
    const diaSemana = diasDaSemana[getDay(dataAtual)].toUpperCase();
    const dataFormatada = format(
      dataAtual,
      "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
      { locale: ptBR },
    ).toUpperCase();

    const lojaInfo = [
      { text: loja.des_fantasia, bold: true, fontSize: 12 },
      {
        text: `${loja.des_logradouro} - Nº: ${loja.num_endereco} - ${loja.des_bairro} CEP: ${loja.num_cep} ${loja.des_cidade}`,
        fontSize: 10,
      },
      { text: `CNPJ: ${loja.num_cnpj}`, fontSize: 10 },
      { text: `${diaSemana}, ${dataFormatada}`, fontSize: 10 },
    ];

    this.docDefinition.content.push({ stack: lojaInfo, margin: [0, 0, 0, 10] });
  }

  private addHeader() {
    const visibleHeaders = PDFGenerator.getVisibleHeaders();
    const columnCount = visibleHeaders.length;

    // Define larguras fixas para as colunas
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);

    // Ajustes específicos para algumas colunas
    const adjustedColumnWidths = columnWidths.map((width, idx) => {
      switch (idx) {
        case 0:
          return width - 27;
        case 1:
          return width - 19;
        case 2:
          return width - 7;
        case 3:
          return width + 13;
        case 4:
          return width + 16;
        case 7:
          return width - 12;
        case 9:
          return width + 11;
        case 11:
          return width + 8;
        case 14:
          return width - 12;
        case 16:
          return width - 12;
        case 17:
          return width + 11;
        default:
          return width - 2;
      }
    });

    const headerCells = visibleHeaders.map((item, idx) => ({
      text: item.headerName,
      alignment: [3, 12, 13, 14, 15, 17, 18, 19].includes(idx)
        ? 'right'
        : 'left',
      fontSize: 7.1,
      border: [false, true, false, true],
      margin: 0,
    }));

    const header = {
      table: {
        widths: adjustedColumnWidths,
        headerRows: 1,
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        body: [headerCells],
      },
      layout: {
        defaultBorder: false,
      },
      margin: 0,
    };

    this.docDefinition.content.push(header);
  }

  private filterItems(
    items: ApuracaoGridProps[],
    dentroEstado: boolean,
    tipoOperacao: number,
  ) {
    this.dummy = false;
    return formataPDFValue(items).filter(
      (item) =>
        item.flg_dentro_estado === dentroEstado &&
        item.tipo_operacao === tipoOperacao,
    );
  }
  private createCell(value: any, idx: number) {
    this.dummy = false;
    return {
      text:
        value === '' || value === null || value === undefined
          ? '-'
          : String(value),
      alignment: 'right',
      fontSize: 7,
      bold: !!(idx === 0 || idx === 1),
      border: [false, false, false, false],
      margin: 0,
    };
  }
  private generateRows(
    items: ApuracaoGridProps[],
    visibleHeaders: HeaderPdfProps[],
  ) {
    return items.map((item) => {
      return visibleHeaders.map((header, idx) => {
        const key = header.key as keyof ApuracaoGridProps;
        const value = item[key];
        return this.createCell(value, idx);
      });
    });
  }

  private addContentEntradas() {
    const { items } = this;
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;

    // Define larguras fixas para as colunas
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    const newColumnWidths: number[] = columnWidths.map((width) => width - 0.5);
    newColumnWidths[0] -= 25;
    newColumnWidths[1] -= 16;
    newColumnWidths[2] -= 10;
    newColumnWidths[3] += 15;
    newColumnWidths[4] += 15;
    newColumnWidths[6] += 3;
    newColumnWidths[7] -= 13;
    newColumnWidths[9] += 10;
    newColumnWidths[11] += 10;
    newColumnWidths[12] += 5;
    newColumnWidths[14] -= 5;
    newColumnWidths[15] -= 8;

    const valColumns = visibleHeaders
      .map((header) => header.key as string)
      .filter((field) => field.startsWith('val_'));

    const entradaDentroDoEstado = this.filterItems(items, true, 0);
    const entradaForaDoEstado = this.filterItems(items, false, 0);

    // Gerar conteúdo da tabela para itens de entrada
    const entradaInUFRows = this.generateRows(
      entradaDentroDoEstado,
      visibleHeaders,
    );

    const entradaInUFTotalsRow = visibleHeaders.map((header) => {
      const key = header.key as string;
      if (valColumns.includes(key)) {
        return {
          text: `${
            this.totalInUFEntrada[key] === 0
              ? ''
              : moneyFormat(this.totalInUFEntrada[key].toFixed(2)) || ''
          }`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#d9d9d9',
          margin: 0,
        };
      }
      return {
        text: '',
        alignment: 'right',
        fontSize: 7,
        border: [false, false, false, false],
        fillColor: '#d9d9d9',
        margin: 0,
      };
    });

    const entradaOutUFRows = this.generateRows(
      entradaForaDoEstado,
      visibleHeaders,
    );

    const entradaOutUFTotalsRow = visibleHeaders.map((header) => {
      const key = header.key as string;
      if (valColumns.includes(key)) {
        return {
          text: `${
            this.totalOutUFEntrada[key] === 0
              ? ''
              : moneyFormat(this.totalOutUFEntrada[key].toFixed(2)) || ''
          }`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#d9d9d9',
          margin: 0,
        };
      }
      return {
        text: '',
        alignment: 'right',
        fontSize: 7,
        border: [false, false, false, false],
        fillColor: '#d9d9d9',
        margin: 0,
      };
    });

    const content = [
      {
        table: {
          widths: newColumnWidths,
          dontBreakRows: true,
          body: [
            ...entradaInUFRows,
            entradaInUFTotalsRow,
            ...entradaOutUFRows,
            entradaOutUFTotalsRow,
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(...content);
  }
  private addSubTotalEntradas() {
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    columnWidths[0] += 30;
    columnWidths[1] -= 73;
    columnWidths[4] += 20;
    columnWidths[7] -= 13;
    columnWidths[9] += 10;
    columnWidths[10] += 10;
    columnWidths[12] += 5;
    columnWidths[13] -= 3;
    columnWidths[14] -= 5;
    columnWidths[17] -= 10;

    const valColumns = visibleHeaders
      .map((header) => header.key as string)
      .filter((field) => field.startsWith('val_'));

    const entradaTotalsRow = visibleHeaders.map((header, idx) => {
      const key = header.key as string;
      if (valColumns.includes(key)) {
        return {
          text: `${
            this.totalEntrada[key] === 0
              ? ''
              : moneyFormat(this.totalEntrada[key].toFixed(2)) || ''
          }`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#bfbfbf',
          margin: 0,
        };
      }
      return {
        text: idx === 0 ? 'Sub.Total Entradas' : '',
        alignment: idx === 0 || idx === 13 || idx === 14 ? 'left' : 'right',
        fontSize: 7,
        border: [false, false, false, false],
        fillColor: '#bfbfbf',
        bold: true,
        margin: 0,
      };
    });

    const content = [
      {
        table: {
          widths: columnWidths,
          dontBreakRows: true,
          body: [entradaTotalsRow],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(...content);
  }

  private addContentSaidas() {
    const { items } = this;
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;

    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    const newColumnWidths: number[] = columnWidths.map((width) => width - 0.5);
    newColumnWidths[0] -= 25;
    newColumnWidths[1] -= 16;
    newColumnWidths[2] -= 10;
    newColumnWidths[3] += 15;
    newColumnWidths[4] += 15;
    newColumnWidths[6] += 3;
    newColumnWidths[7] -= 13;
    newColumnWidths[9] += 10;
    newColumnWidths[11] += 10;
    newColumnWidths[12] += 5;
    newColumnWidths[14] -= 5;
    newColumnWidths[15] -= 8;

    const valColumns = visibleHeaders
      .map((header) => header.key as string)
      .filter((field) => field.startsWith('val_'));

    const saidaDentroDoEstado = this.filterItems(items, true, 1);
    const saidaForaDoEstado = this.filterItems(items, false, 1);

    const saidaInUFRows = this.generateRows(
      saidaDentroDoEstado,
      visibleHeaders,
    );
    const saidaInUFTotalsRow = visibleHeaders.map((header) => {
      const key = header.key as string;
      if (valColumns.includes(key)) {
        return {
          text: `${
            this.totalInUFSaida[key] === 0
              ? ''
              : moneyFormat(this.totalInUFSaida[key].toFixed(2)) || ''
          }`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#d9d9d9', // Cor de fundo para a linha de totais
          margin: [0, 0, 0, 0],
        };
      }
      return {
        text: '',
        alignment: 'right',
        fontSize: 7,
        border: [false, false, false, false],
        fillColor: '#d9d9d9', // Cor de fundo para a linha de totais
        margin: [0, 0, 0, 0],
      };
    });
    const saidaOutUFRows = this.generateRows(saidaForaDoEstado, visibleHeaders);
    const saidaOutUFTotalsRow = visibleHeaders.map((header) => {
      const key = header.key as string;
      if (valColumns.includes(key)) {
        return {
          text: `${
            this.totalOutUFSaida[key] === 0
              ? ''
              : moneyFormat(this.totalOutUFSaida[key].toFixed(2)) || ''
          }`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#d9d9d9', // Cor de fundo para a linha de totais
          margin: [0, 0, 0, 0],
        };
      }
      return {
        text: '',
        alignment: 'right',
        fontSize: 7,
        border: [false, false, false, false],
        fillColor: '#d9d9d9', // Cor de fundo para a linha de totais
        margin: [0, 0, 0, 0],
      };
    });

    const content = [
      {
        table: {
          widths: newColumnWidths,
          dontBreakRows: true,
          body: [
            ...saidaInUFRows,
            saidaInUFTotalsRow,
            ...saidaOutUFRows,
            saidaOutUFTotalsRow,
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(...content);
  }
  private addSubTotalSaidas() {
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    columnWidths[0] += 30;
    columnWidths[1] -= 73;
    columnWidths[4] += 20;
    columnWidths[5] -= 10;
    columnWidths[6] += 10;
    columnWidths[7] -= 13;
    columnWidths[9] += 10;
    columnWidths[10] += 10;
    columnWidths[12] += 5;
    columnWidths[14] -= 5;
    columnWidths[17] -= 13;

    const valColumns = visibleHeaders
      .map((header) => header.key as string)
      .filter((field) => field.startsWith('val_'));

    const saidaTotalsRow = visibleHeaders.map((header, idx) => {
      const key = header.key as string;
      if (valColumns.includes(key)) {
        return {
          text: `${
            this.totalSaida[key] === 0
              ? ''
              : moneyFormat(this.totalSaida[key].toFixed(2)) || ''
          }`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#bfbfbf', // Cor de fundo para a linha de totais
          margin: 0,
        };
      }
      return {
        text: idx === 0 ? 'Sub.Total Saídas' : '',
        alignment: idx === 0 || idx === 14 ? 'left' : 'right',
        fontSize: 7,
        bold: true,
        border: [false, false, false, false],
        fillColor: '#bfbfbf', // Cor de fundo para a linha de totais
        margin: 0,
      };
    });

    const content = [
      {
        table: {
          widths: columnWidths,
          dontBreakRows: true,
          body: [saidaTotalsRow],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(...content);
  }

  private addRetencoes() {
    const retencoes = [
      {
        table: {
          widths: ['100'],
          dontBreakRows: true,
          body: [
            [
              {
                text: 'Retenções',
                alignment: 'left',
                fontSize: 7,
                bold: true,
                border: [false, false, false, false],
                margin: 0,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(retencoes);
  }
  private addSubTotalRetencoes() {
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    columnWidths[0] += 788;

    const subTotalretencoes = [
      {
        table: {
          widths: columnWidths,
          dontBreakRows: true,
          body: [
            [
              {
                text: 'Sub. Total Retenções',
                alignment: 'left',
                fontSize: 7,
                bold: true,
                border: [false, false, false, false],
                margin: 0,
                fillColor: '#d9d9d9',
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(subTotalretencoes);
  }

  private addImpostoApurado() {
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    columnWidths[0] += 25;
    columnWidths[7] -= 60;
    columnWidths[9] += 20;
    columnWidths[15] -= 14;

    const calcApuradoPis = this.totalEntrada.val_pis - this.totalSaida.val_pis;
    const calcApuradoCofins =
      this.totalEntrada.val_cofins - this.totalSaida.val_cofins;

    const valPis = moneyFormat(calcApuradoPis.toFixed(2));
    const valCofins = moneyFormat(calcApuradoCofins.toFixed(2));

    const totals: { val_pis: string; val_cofins: string } = {
      val_pis: valPis,
      val_cofins: valCofins,
    };

    const valColumns = visibleHeaders
      .map((header) => header.key as string)
      .filter((field) => field === 'val_pis' || field === 'val_cofins');

    const items = visibleHeaders.map((header, idx) => {
      const key = header.key as 'val_pis' | 'val_cofins';
      if (valColumns.includes(key)) {
        return {
          text: `${totals[key] === '0.00' ? '' : totals[key] || ''}`,
          alignment: 'right',
          fontSize: 7,
          border: [false, false, false, false],
          fillColor: '#bfbfbf',
          margin: 0,
        };
      }
      return {
        text: idx === 0 ? 'Imposto Apurado' : '',
        alignment: 'left',
        fontSize: 7,
        border: [false, false, false, false],
        bold: true,
        fillColor: '#bfbfbf',
        margin: 0,
      };
    });

    const impostoApurado = [
      {
        table: {
          widths: columnWidths,
          dontBreakRows: true,
          body: [items],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(impostoApurado);
  }

  private addFooter() {
    const startDate = format(this.dates.dta_inicio, 'dd/MM/yyyy');
    const endDate = format(this.dates.dta_fim, 'dd/MM/yyyy');
    const companyName = 'Konvix Tecnologia - www.konvix.com.br';

    const footerText = `Relatório de Apuração PIS/COFINS - Período (${startDate} - ${endDate}) \n \n ${companyName}`;

    const footerContent = {
      text: footerText,
      alignment: 'right',
      fontSize: 8,
      margin: 0,
      bold: true,
    };

    const footerTable = {
      table: {
        dontBreakRows: true,
        body: [[footerContent]],
      },
      margin: [565, 5, 0, 0],
      layout: 'noBorders',
    };

    this.docDefinition.content.push(footerTable);
  }

  public generatePDF(): void {
    try {
      this.addInfoLoja();
      this.addHeader();

      if (this.items.filter((item) => item.tipo_operacao === 0).length > 0) {
        this.addContentEntradas();
        this.addSubTotalEntradas();
      }
      if (this.items.filter((item) => item.tipo_operacao === 1).length > 0) {
        this.addContentSaidas();
        this.addSubTotalSaidas();
      }

      this.addRetencoes();
      this.addSubTotalRetencoes();
      this.addImpostoApurado();

      this.addFooter();

      const pdfDocGenerator = pdfMake.createPdf(this.docDefinition);

      // Tentando abrir o PDF
      pdfDocGenerator.open(); // Isso abre o PDF em uma nova aba/janela

      // Tentando baixar o PDF
      // pdfDocGenerator.download('documento.pdf'); // Isso faz o download do PDF
    } catch (error) {
      console.log('Erro ao gerar o PDF:', error);
    }
  }
}

export default PDFGenerator;
