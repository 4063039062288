import styled from 'styled-components';

export const ContainerButtons = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  margin-top: 25px;
  gap: 0.9375rem;
`;
