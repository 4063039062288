import React from 'react';
import { totalizadoresArray } from '../../utils/totalizadores';
import { ContainerToalizadores } from './styles';
import { useApuracaoDePisCofins } from '../../hooks/useApuracaoDePisCofins';

type TotalizadorItem = {
  label: string;
  value: string;
};

const TotalizadorRow: React.FC<{ items: TotalizadorItem[] }> = ({ items }) => (
  <div className="row">
    {items.map((item) => (
      <div key={item.label} className="item">
        <strong>{item.label}</strong>: {item?.value ?? '0,00'}
      </div>
    ))}
  </div>
);

export const Totalizadores: React.FC = () => {
  const { totalizadores } = useApuracaoDePisCofins();
  const array = totalizadores.length > 0 ? totalizadores : totalizadoresArray;

  const filterItems = (prefix: string) =>
    array.filter((item) => item?.label?.startsWith(prefix));

  const credItems = filterItems('Créd');
  const debItems = filterItems('Déb');
  const impItems = filterItems('Imp');
  const totItems = filterItems('Tot');

  return (
    <ContainerToalizadores>
      <TotalizadorRow
        items={[...credItems, ...(totItems[0] ? [totItems[0]] : [])]}
      />
      <TotalizadorRow
        items={[...debItems, ...(totItems[1] ? [totItems[1]] : [])]}
      />
      <TotalizadorRow
        items={[...impItems, ...(totItems[2] ? [totItems[2]] : [])]}
      />
      {totItems.length > 3 && (
        <div className="row">
          <div className="item empty" />
          <div className="item empty" />
          <div className="item empty" />
          <div className="item last">
            <strong>{totItems[3].label}</strong>: {totItems[3]?.value ?? '0,00'}
          </div>
        </div>
      )}
    </ContainerToalizadores>
  );
};
