import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }

  .parceiroContainer input:disabled {
    cursor: not-allowed !important;
  }

  .ButtonLoja .form-check-input:disabled {
    cursor: not-allowed !important;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
  }

  .cell-apuracao-val-pis,
  .cell-apuracao-val-cofins {
    background-color: #c0dcc0;
  }

  .totalizadores-buttons {
    margin-top: 0.9375rem;

    .container-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      margin-top: 0.9375rem;
      gap: 0.9375rem;
    }
  }
`;
