import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ApuracaoDePisCofinsContextData,
  ApuracaoDePisCofinsProviderProps,
  ApuracaoGridProps,
  DisableButtonProps,
  SearchProps,
  totalizadoresFieldsProps,
  UseFormApuracaoProps,
} from '../types/context';
import { useForm } from 'react-hook-form';
import { format, getMonth, getYear, isValid, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { LojaContext } from '~/context/loja';
import {
  ApuracaoPisCofinsProps,
  InfoLojaProps,
  ParamsIndexApuracaoProps,
} from '../types';
import { apuracaoServices } from '../services';
import { nanoid } from 'nanoid';
import { somaCampo } from '../utils/somaCampo';
import { moneyFormat } from '~/utils/functions';
import { totalizadoresArray } from '../utils/totalizadores';
import { addValueToTotalizadores } from '../utils/addValueToTotalizadores';
import { operacao } from '../utils/operacao';
import { pisCofins } from '../utils/pis-cofins';
import { tipoNaoIncidencia } from '../utils/tipo-nao-incidencia';
import { useQueryClient } from 'react-query';

export const ApuracaoDePisCofinsContext = createContext(
  {} as ApuracaoDePisCofinsContextData,
);

export const ApuracaoDePisCofinsProvider: React.FC<
  ApuracaoDePisCofinsProviderProps
> = ({ children }) => {
  const { loja } = useContext(LojaContext);
  const [itemsGrid, setItemsGrid] = useState<ApuracaoGridProps[]>([]);
  const [disableForm, setDisableForm] = useState(false);
  const [disableButtons, setDisableButtons] = useState<DisableButtonProps>({
    search: false,
    cancel: true,
    clear: false,
  });
  const [loading, setLoading] = useState(false);
  const [disableTipoNaoIncidencia, setDisableTipoNaoIncidencia] =
    useState(true);
  const [totalizadores, setTotalizadores] = useState<any[]>([]);
  const [dates, setDates] = useState<{
    dta_inicio: Date | null;
    dta_fim: Date | null;
  }>({
    dta_inicio: null,
    dta_fim: null,
  });
  const [infoLoja, setInfoLoja] = useState<InfoLojaProps | null>(null);
  const [codLoja, setCodLoja] = useState<number[] | null>(null);
  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined,
  );
  const queryClient = useQueryClient();

  const containerInputsRef = useRef<HTMLElement>(null);

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({ reValidateMode: 'onChange' });

  const delay = useCallback((ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }, []);

  const formTela: UseFormApuracaoProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  useEffect(() => {
    setValue('tipo_operacao', operacao[0]);
    setValue('flg_nao_pis_cofins', pisCofins[0]);
    setValue('tipo_nao_pis_cofins', tipoNaoIncidencia[0]);
  }, [setValue]);

  const validateDates = useCallback(
    (dta_inicio: Date, dta_fim: Date) => {
      const isValidDate = (date: Date) => isValid(date);
      const isEmptyDate = (date: Date) => !isValidDate(date);
      const isStartDateAfterEndDate = (startDate: Date, endDate: Date) =>
        isValidDate(startDate) && isValidDate(endDate) && startDate > endDate;
      const isEndDateBeforeStartDate = (startDate: Date, endDate: Date) =>
        isValidDate(startDate) && isValidDate(endDate) && endDate < startDate;

      if (isEmptyDate(dta_inicio) && isEmptyDate(dta_fim)) {
        setError('dta_inicio', { type: 'required' });
        setError('dta_fim', { type: 'required' });
        return false;
      }

      if (isEmptyDate(dta_inicio)) {
        setError('dta_inicio', { type: 'required' });
        return false;
      }
      if (isEmptyDate(dta_fim)) {
        setError('dta_fim', { type: 'required' });
        return false;
      }
      if (isStartDateAfterEndDate(dta_inicio, dta_fim)) {
        setError('dta_inicio', {
          type: 'invalid',
          message: 'A data de início não pode ser maior que a data de fim.',
        });
        toast.warning('A data de início não pode ser maior que a data de fim');
        return false;
      }
      if (isEndDateBeforeStartDate(dta_inicio, dta_fim)) {
        setError('dta_fim', {
          type: 'invalid',
          message: 'A data de fim não pode ser menor que a data de início.',
        });
        toast.warning('A data de fim não pode ser menor que a data de início');
        return false;
      }

      const startMonth = getMonth(dta_inicio);
      const startYear = getYear(dta_inicio);
      const endMonth = getMonth(dta_fim);
      const endYear = getYear(dta_fim);

      if (startMonth !== endMonth || startYear !== endYear) {
        toast.warning('Período informado deve estar no mesmo mês e ano');
        return false;
      }
      return true;
    },
    [setError],
  );
  const validateFields = useCallback((field: any): boolean => {
    if (field === undefined || field === null) return false;

    if (typeof field === 'string' && field.trim() === '') return false;

    if (Array.isArray(field) && field.length <= 0) return false;

    if (
      typeof field === 'object' &&
      !Array.isArray(field) &&
      Object.keys(field).length <= 0
    )
      return false;

    return true;
  }, []);

  const onSearch = useCallback(async () => {
    const dta_inicio = parseISO(getValues('dta_inicio'));
    const dta_fim = parseISO(getValues('dta_fim'));

    if (!validateDates(dta_inicio, dta_fim)) return;

    clearErrors(['dta_inicio', 'dta_fim']);
    setDates({ dta_inicio, dta_fim });

    const flg_nao_pis_cofins = getValues('flg_nao_pis_cofins');
    const tipo_nao_pis_cofins = getValues('tipo_nao_pis_cofins');
    const tipo_operacao = getValues('tipo_operacao');

    if (
      !validateFields(flg_nao_pis_cofins) ||
      !validateFields(tipo_nao_pis_cofins) ||
      !validateFields(tipo_operacao)
    ) {
      toast.warning('Verifique os valores informados');
      return;
    }

    if (flg_nao_pis_cofins.value === 'Todos')
      setValue('flg_nao_pis_cofins', pisCofins[0]);

    if (tipo_nao_pis_cofins.value === 'Todos')
      setValue('tipo_nao_pis_cofins', tipoNaoIncidencia[0]);

    if (tipo_operacao.value === 'Todos') setValue('tipo_operacao', operacao[0]);

    setLoading(true);

    await delay(200);

    const params: ParamsIndexApuracaoProps = {
      flg_nao_pis_cofins: Number(flg_nao_pis_cofins.value),
      tipo_nao_pis_cofins: Number(tipo_nao_pis_cofins.value),
      dta_inicio: format(dta_inicio, 'yyyy-MM-dd'),
      dta_fim: format(dta_fim, 'yyyy-MM-dd'),
      cod_loja: codLoja ?? [loja.cod_loja],
      tipo_operacao: Number(tipo_operacao.value),
      des_sigla: loja.des_uf,
    };

    const respApuracao = await apuracaoServices.indexApuracao({ params });

    if (respApuracao.infoLoja) setInfoLoja(respApuracao.infoLoja);
    else setInfoLoja(null);

    if (respApuracao.apuracao.length > 0) {
      const ap = respApuracao.apuracao.map((item) => ({
        id: nanoid(),
        ...item,
      }));

      const entradas = ap.filter((item) => item.tipo_operacao === 0);
      const saidas = ap.filter((item) => item.tipo_operacao === 1);

      const calculateTotals = (
        entrance: any,
        out: any,
        field: keyof ApuracaoPisCofinsProps,
      ) => {
        const credit = moneyFormat(String(somaCampo(entradas, field, true)));
        const debit = moneyFormat(String(somaCampo(saidas, field, true)));
        const calculatedValue = (
          Number(somaCampo(entradas, field, false)) -
          Number(somaCampo(saidas, field, false))
        ).toFixed(2);

        return { credit, debit, calculatedValue };
      };

      const pis = calculateTotals(entradas, saidas, 'val_pis');
      const cofins = calculateTotals(entradas, saidas, 'val_cofins');
      const pis_cofins = calculateTotals(entradas, saidas, 'val_imposto');
      const tot_incid_cpra = moneyFormat(
        String(somaCampo(entradas, 'val_bc', true)),
      );
      const tot_n_incid_cpra = moneyFormat(
        String(somaCampo(entradas, 'val_isento', true)),
      );
      const tot_incid_vda = moneyFormat(
        String(somaCampo(saidas, 'val_bc', true)),
      );
      const tot_n_incid_vda = moneyFormat(
        String(somaCampo(saidas, 'val_isento', true)),
      );

      const values: totalizadoresFieldsProps = {
        cred_pis: pis.credit,
        deb_pis: pis.debit,
        imp_pis: moneyFormat(pis.calculatedValue),
        cred_cofins: cofins.credit,
        deb_cofins: cofins.debit,
        imp_cofins: moneyFormat(cofins.calculatedValue),
        cred_pis_cofins: pis_cofins.credit,
        deb_pis_cofins: pis_cofins.debit,
        imp_pis_cofins: moneyFormat(pis_cofins.calculatedValue),
        tot_incid_cpra,
        tot_n_incid_cpra,
        tot_incid_vda,
        tot_n_incid_vda,
      };

      const researchDone: SearchProps = {
        totalizadoresValue: values,
        gridItems: ap,
        formDisabled: true,
        buttonsDisabled: { search: true, cancel: false, clear: true },
        dtaInicio: dta_inicio,
        dtaFim: dta_fim,
        operacaoValue: tipo_operacao,
        pisCofinsValue: flg_nao_pis_cofins,
        tipoNaoIncidenciaValue: tipo_nao_pis_cofins,
        codLojaValue: codLoja ?? [loja.cod_loja],
      };

      setSearchData(researchDone);

      setTotalizadores(addValueToTotalizadores(totalizadoresArray, values));
      setItemsGrid(ap);
      setDisableForm(true);
      setDisableButtons({ search: true, cancel: false, clear: true });
    } else {
      setSearchData(undefined);
      setItemsGrid([]);
      setDisableForm(false);
      setDisableButtons({ search: false, cancel: true, clear: false });
      setTotalizadores([]);
      toast.warning('Nenhum registro encontrado com os dados fornecidos');
    }

    setLoading(false);
  }, [
    clearErrors,
    codLoja,
    delay,
    getValues,
    loja.cod_loja,
    loja.des_uf,
    setValue,
    validateDates,
    validateFields,
  ]);

  const onChangeDisableTipoNaoIncidencia = useCallback((value: boolean) => {
    setDisableTipoNaoIncidencia(value);
  }, []);

  const onChangeCodLoja = useCallback((cod: number[] | null) => {
    setCodLoja(cod);
  }, []);

  const handleCancel = useCallback(() => {
    setItemsGrid([]);
    setDisableForm(false);
    setDisableButtons({ search: false, cancel: true, clear: false });
    setTotalizadores([]);
    setSearchData(undefined);
    queryClient.removeQueries('cache_tela_295');
  }, [queryClient]);

  const handleClear = useCallback(() => {
    reset();
    setValue('dta_inicio', '');
    setValue('dta_fim', '');
    setValue('tipo_operacao', operacao[0]);
    setValue('flg_nao_pis_cofins', pisCofins[0]);
    setValue('tipo_nao_pis_cofins', tipoNaoIncidencia[0]);
    setDisableTipoNaoIncidencia(true);
    handleCancel();

    if (containerInputsRef.current) {
      const inputSelectors = [
        { name: 'tipo_operacao', value: 'Todos' },
        { name: 'flg_nao_pis_cofins', value: 'Todos' },
        { name: 'tipo_nao_pis_cofins', value: 'Todos' },
      ];

      inputSelectors.forEach(({ name, value }) => {
        const inputElement =
          containerInputsRef.current?.querySelector<HTMLInputElement>(
            `[name="${name}"]`,
          );
        if (inputElement) inputElement.value = value;
      });
    }
  }, [handleCancel, reset, setValue]);

  useEffect(() => {
    if (
      window.location.pathname === '/apuracao-pis-cofins' &&
      itemsGrid.length <= 0
    ) {
      const storedData: SearchProps | undefined =
        queryClient.getQueryData('cache_tela_295');

      if (storedData) {
        const {
          buttonsDisabled,
          gridItems,
          totalizadoresValue,
          formDisabled,
          dtaInicio,
          dtaFim,
          operacaoValue,
          pisCofinsValue,
          tipoNaoIncidenciaValue,
        } = storedData;

        setDisableButtons(buttonsDisabled);
        setItemsGrid(gridItems);
        setTotalizadores(
          addValueToTotalizadores(totalizadoresArray, totalizadoresValue),
        );
        setDisableForm(formDisabled);
        setValue('dta_inicio', format(new Date(dtaInicio), 'yyy-MM-dd'));
        setValue('dta_fim', format(new Date(dtaFim), 'yyy-MM-dd'));
        setValue('tipo_operacao', operacaoValue);
        setValue('flg_nao_pis_cofins', pisCofinsValue);
        setValue('tipo_nao_pis_cofins', tipoNaoIncidenciaValue);
      }
    }
  }, [itemsGrid.length, queryClient, setValue, window.location.pathname]);

  return (
    <ApuracaoDePisCofinsContext.Provider
      value={{
        formTela,
        itemsGrid,
        disableForm,
        disableButtons,
        loading,
        disableTipoNaoIncidencia,
        totalizadores,
        dates,
        infoLoja,
        codLoja,
        containerInputsRef,
        searchData,

        onSearch,
        handleCancel,
        handleClear,
        onChangeDisableTipoNaoIncidencia,
        onChangeCodLoja,
      }}
    >
      {children}
    </ApuracaoDePisCofinsContext.Provider>
  );
};
