import { HeaderPdfProps } from '../types';

export const headerPDF: HeaderPdfProps[] = [
  { key: 'cod_loja', headerName: 'Lj', hide: false },
  { key: 'cfop', headerName: 'Cod', hide: false },
  { key: 'cst_pis', headerName: 'CST PIS', hide: false },
  { key: 'cst_cofins', headerName: 'CST Cofins', hide: false },
  { key: 'val_total', headerName: 'Valor Contábil', hide: false },
  { key: 'val_icms_st', headerName: 'ICMS ST', hide: false },
  { key: 'val_base_pis', headerName: 'Base PIS', hide: false },
  { key: 'per_pis', headerName: '% PIS', hide: false },
  { key: 'val_pis', headerName: 'Valor Pis', hide: false },
  { key: 'val_base_cofins', headerName: 'Base Cofins', hide: false },
  { key: 'per_cofins', headerName: '% Cofins', hide: false },
  { key: 'val_cofins', headerName: 'Valor Cofins', hide: false },
  { key: 'val_aliq_zero', headerName: 'Zero', hide: false },
  { key: 'val_monofasico', headerName: 'Monof.', hide: false },
  { key: 'val_st', headerName: 'S.T.', hide: false },
  { key: 'val_imune', headerName: 'Imune', hide: false },
  { key: 'val_suspensao', headerName: 'Susp.', hide: false },
  { key: 'val_nao_tribut_pis_cofins', headerName: 'Não Tribut.', hide: false },
  { key: 'val_isento_pis_cof', headerName: 'Isento', hide: false },
  { key: 'val_ipi', headerName: 'Val. IPI', hide: false },
  { key: 'val_desconto', headerName: 'Val Desc', hide: false },
];
