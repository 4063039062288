import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';

export const tipoNaoIncidencia: SelectType[] = [
  { label: 'Todos', value: -1 },
  { label: 'Alíquota Zero', value: 0 },
  { label: 'Monofásico', value: 1 },
  { label: 'Substituição', value: 2 },
  { label: 'Imune', value: 3 },
  { label: 'Suspensão', value: 4 },
  { label: 'Não Tributado', value: 5 },
  { label: 'Isento', value: 6 },
];
