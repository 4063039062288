import { moneyFormat } from '~/utils/functions';
import { ApuracaoGridProps } from '../types/context';

export const formataPDFValue = (
  items: ApuracaoGridProps[],
): ApuracaoGridProps[] => {
  return items.map((item) => {
    const newItem: any = { ...item };

    Object.entries(newItem).forEach(([key, value]) => {
      if (
        (key.startsWith('val_') || key.startsWith('per_')) &&
        (typeof value === 'string' || typeof value === 'number')
      ) {
        newItem[key] = moneyFormat(value.toString());
      }
    });

    return newItem;
  });
};
