/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { totalizadoresArray } from './totalizadores';

export const addValueToTotalizadores = (
  totalizadores: typeof totalizadoresArray,
  valores: any,
): typeof totalizadoresArray => {
  return totalizadores.map((item) => {
    const value = valores[item.key];
    return { ...item, value };
  });
};
